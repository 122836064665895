import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { BodyText } from '../../styles'
import media from '../../styles/media'

const OrderTable = ({ quoteItems, emailAddress, pageShown }) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }, [width])

  const OrderDiv = styled.div`
    .date {
      margin-top: 50px;
    }
    table {
      width: 100%;
      text-align: left;
      border-collapse: collapse;
      thead {
        font-size: 12px;
        line-height: 18px;
        font-family: 'neue-haas-grotesk-display-bold';
      }
      tr {
        height: 65px;
        border-bottom: 1px solid var(--light-grey);
        td {
          padding: 10px;
          font-size: 10px;
          &.blue {
            color: var(--blue);
          }
          &.bold {
            font-family: 'neue-haas-grotesk-display-bold';
          }
        }
      }
    }

    ${media.mobileL`
      table {
        thead {
          font-size: 12px;
          line-height: 18px;
          font-family: 'neue-haas-grotesk-display-bold';
        }
        tr {
          height: 50px;
          td {
          font-size: 12px;
          }
        }
      }
    `}
    ${media.laptop`
      table {
        min-width: 600px;
        thead {
          font-size: 14px;
          line-height: 21px;
          font-family: 'neue-haas-grotesk-display-bold';
        }
        tr {
          td {
            font-size: 14px;
          }
        }
      }
    `}
  `

  const myQuotes = []

  for (const item of quoteItems) {
    if (item.email === emailAddress) {
      myQuotes.push(item.products)
    }
  }

  return (
    <OrderDiv>
      {myQuotes.map((quote, i) => (
        <div key={i}>
          <BodyText className="black date client">
            Placed on {new Date(quote[0].date.seconds * 1000).toDateString()}
          </BodyText>
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>SKU</th>
                <th>Price</th>
                <th>Quantitiy</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="blue">{quote[0].title}</td>
                <td>{quote[0].sku}</td>
                <td>R {quote[0].price}.00</td>
                <td>{quote[0].quantity}</td>
                <td>R {quote[0].quantity * quote[0].price}.00</td>
              </tr>
              {quote[1] && (
                <tr>
                  <td className="blue">{quote[1].title}</td>
                  <td>{quote[1].sku}</td>
                  <td>R {quote[1].price}.00</td>
                  <td>{quote[1].quantity}</td>
                  <td>R {quote[1].quantity * quote[1].price}.00</td>
                </tr>
              )}
              {quote[2] && (
                <tr>
                  <td className="blue">{quote[2].title}</td>
                  <td>{quote[2].sku}</td>
                  <td>R {quote[2].price}.00</td>
                  <td>{quote[2].quantity}</td>
                  <td>R {quote[2].quantity * quote[2].price}.00</td>
                </tr>
              )}
              {quote[3] && (
                <tr>
                  <td className="blue">{quote[3].title}</td>
                  <td>{quote[3].sku}</td>
                  <td>R {quote[3].price}.00</td>
                  <td>{quote[3].quantity}</td>
                  <td>R {quote[3].quantity * quote[3].price}.00</td>
                </tr>
              )}
              {quote[4] && (
                <tr>
                  <td className="blue">{quote[4].title}</td>
                  <td>{quote[4].sku}</td>
                  <td>R {quote[4].price}.00</td>
                  <td>{quote[4].quantity}</td>
                  <td>R {quote[4].quantity * quote[4].price}.00</td>
                </tr>
              )}
              <tr>
                <td>Subtotal</td>
                <td></td>
                <td></td>
                <td></td>
                <td>R {quote[0].total}.00</td>
              </tr>
              <tr>
                <td className="bold">Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="bold">R {quote[0].total}.00</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </OrderDiv>
  )
}

export default OrderTable
