import React from 'react'
import { BodyText } from '../../styles'

const DefaultAddressShow = ({ defaultAddress, addressesSize }) => {
  return (
    <div className="column has-text-centered">
      {defaultAddress != null && (
        <div className="has-text-left">
          <BodyText className="black client">
            {defaultAddress.firstName} {defaultAddress.lastName}
          </BodyText>
          <BodyText className="black client">
            {defaultAddress.address1}
          </BodyText>
          <BodyText className="black client">
            {defaultAddress.zip}, {defaultAddress.city}
          </BodyText>
          <BodyText className="black ">{defaultAddress.country}</BodyText>
        </div>
      )}
      <br />
    </div>
  )
}

export default DefaultAddressShow
