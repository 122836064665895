import React, { useContext, useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import axios from 'axios'
import StoreContext from '../../context/StoreContext'
import Layout from '../../components/account/Layout'
import AddAddressForm from '../../components/account/adresses/addAddressForm'
import styled from 'styled-components'
import { BodyLink, BodyText, Button } from '../../styles'
import media from '../../styles/media'

const CUSTOMER_ADDRESS = gql`
  query($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      defaultAddress {
        id
      }
      addresses(first: 10) {
        edges {
          node {
            id
            address1
            address2
            city
            phone
            lastName
            firstName
            country
            name
            zip
          }
        }
      }
    }
  }
`

const CUSTOMER_EDIT_ADDRESS = gql`
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const CUSTOMER_EDIT_DEFAULT_ADDRESS = gql`
  mutation customerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const CUSTOMER_DELETE_ADDRESS = gql`
  mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
    customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        code
        field
        message
      }
      deletedCustomerAddressId
    }
  }
`

const AddressCon = styled.div`
  width: 80vw;
  display: flex;
  justify-content: space-between;
  a {
    margin: 0 10px;
  }
  .actions {
    /* display: flex; */
  }
  ${media.laptop`
    width: 55vw;
  `}
`

const FormDiv = styled.form`
  input[type='text'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    width: 85%;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    border-radius: 0;
    font-family: 'neue-haas-grotesk-display';
    :focus {
      outline: none !important;
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    color: rgb(118, 118, 118);
    font-family: 'neue-haas-grotesk-display';
    width: 95%;
    :focus {
      outline: none !important;
    }
  }
  label {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    font-family: 'neue-haas-grotesk-display-bold';
  }
  ${media.laptop`
        display: grid;
        grid-template-columns: 1fr 1fr;
    `}
`

const Addresses = () => {
  const { customerAccessToken } = useContext(StoreContext)
  const [editAdressForm, setEditAdressForm] = useState(false)
  const [firstNameInput, setFirstNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [companyInput, setCompanyInput] = useState('')
  const [addressInput, setAddressInput] = useState('')
  const [apartmentInput, setApartmentInput] = useState('')
  const [cityInput, setCityInput] = useState('')
  const [countryInput, setCountryInput] = useState('')
  const [zipInput, setZipInput] = useState('')
  const [phoneInput, setPhoneInput] = useState('')
  const [countriesAll, setCountriesAll] = useState([])
  const [checkDefaultAddress, setCheckDefaultAddress] = useState(false)

  const [addressEdit, setAddressEdit] = useState([])

  const getLocations = () => {
    return axios('https://restcountries.eu/rest/v2/all')
  }

  useEffect(() => {
    getLocations().then(({ data }) => {
      setCountriesAll(data)
    })
  }, [])

  const editClick = address => {
    setEditAdressForm(!editAdressForm)
    setAddressEdit(address)
    setFirstNameInput(address.firstName)
    setLastNameInput(address.lastName)
    setCompanyInput(address.company)
    setAddressInput(address.address1)
    setCityInput(address.city)
    setCountryInput(address.country)
    setZipInput(address.zip)
    setPhoneInput(address.phone)
  }

  return (
    <Layout>
      <Query
        query={CUSTOMER_ADDRESS}
        variables={{
          customerAccessToken: customerAccessToken.accessToken,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Fetching</div>
          if (error) return <div>Error</div>
          const { addresses } = data.customer

          return (
            <div className="has-text-centered">
              <div>
                {addresses != null &&
                  addresses.edges.map(address => (
                    <>
                      <AddressCon key={address.node.id}>
                        <BodyText className="black client">
                          {address.node.address1}, {address.node.city},{' '}
                          {address.node.country}, {address.node.zip}
                        </BodyText>
                        <BodyLink
                          className="black bold right"
                          onClick={() => editClick(address.node)}
                        >
                          Edit
                        </BodyLink>
                        <Mutation mutation={CUSTOMER_DELETE_ADDRESS}>
                          {customerAddressDelete => {
                            return (
                              <BodyLink
                                className="black bold right"
                                onClick={() => {
                                  customerAddressDelete({
                                    variables: {
                                      id: address.node.id,
                                      customerAccessToken:
                                        customerAccessToken.accessToken,
                                    },
                                  }).then(result => {
                                    typeof window !== 'undefined' &&
                                      window.location.reload()
                                  })
                                }}
                              >
                                Remove
                              </BodyLink>
                            )
                          }}
                        </Mutation>
                        {/* <EditAddressForm address={address.node} />
                                                <DeleteAddress id={address.node.id}/> */}
                      </AddressCon>
                    </>
                  ))}
              </div>
              <br />
              <br />
              {editAdressForm && (
                <div className="columns is-centered">
                  <div className="column is-6 has-text-left">
                    <Mutation mutation={CUSTOMER_EDIT_ADDRESS}>
                      {customerAddressUpdate => {
                        return (
                          <Mutation mutation={CUSTOMER_EDIT_DEFAULT_ADDRESS}>
                            {customerDefaultAddressUpdate => {
                              return (
                                <FormDiv>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="firstNameInput"
                                    >
                                      First Name
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={firstNameInput}
                                        type="text"
                                        onChange={e =>
                                          setFirstNameInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="lastNameInput"
                                    >
                                      Last Name
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={lastNameInput}
                                        type="text"
                                        onChange={e =>
                                          setLastNameInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="companyInput"
                                    >
                                      Company
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={companyInput}
                                        type="text"
                                        onChange={e =>
                                          setCompanyInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="addressInput"
                                    >
                                      Address
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={addressInput}
                                        type="text"
                                        onChange={e =>
                                          setAddressInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="apartmentInput"
                                    >
                                      Apartment, suite, etc.
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={apartmentInput}
                                        type="text"
                                        onChange={e =>
                                          setApartmentInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="cityInput"
                                    >
                                      City
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={cityInput}
                                        type="text"
                                        onChange={e =>
                                          setCityInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="countryInput"
                                    >
                                      Country
                                    </label>
                                    <div className="control">
                                      <div className="select">
                                        <select
                                          value={countryInput}
                                          onChange={e =>
                                            setCountryInput(e.target.value)
                                          }
                                          onBlur={e =>
                                            setCountryInput(e.target.value)
                                          }
                                          style={{
                                            minWidth: '140px',
                                            maxWidth: '310px',
                                          }}
                                        >
                                          {countriesAll.map(country => (
                                            <option
                                              key={country.name}
                                              value={country.name}
                                            >
                                              {country.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="countryInput"
                                    >
                                      Province
                                    </label>
                                    <div className="control">
                                      <div className="select">
                                        <select defaultValue="Gauteng">
                                          <option value="Western Cape">
                                            Western Cape
                                          </option>
                                          <option value="Eastern Cape">
                                            Eastern Cape
                                          </option>
                                          <option value="Northern Cape">
                                            Northern Cape
                                          </option>
                                          <option value="North West">
                                            North West
                                          </option>
                                          <option value="Free State">
                                            Free State
                                          </option>
                                          <option value="Kwazulu Natal">
                                            Kwazulu Natal
                                          </option>
                                          <option value="Gauteng">
                                            Gauteng
                                          </option>
                                          <option value="Limpopo">
                                            Limpopo
                                          </option>
                                          <option value="Mpumalanga">
                                            Mpumalanga
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label className="label" htmlFor="zipInput">
                                      Postal/Zip Code
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={zipInput}
                                        type="text"
                                        onChange={e =>
                                          setZipInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <label
                                      className="label"
                                      htmlFor="phoneInput"
                                    >
                                      Phone
                                    </label>
                                    <div className="control">
                                      <input
                                        className="input"
                                        value={phoneInput}
                                        type="text"
                                        onChange={e =>
                                          setPhoneInput(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="field">
                                    <div className="control">
                                      <label
                                        className="checkbox"
                                        htmlFor="checkboxDefaultAddress"
                                      >
                                        <input
                                          type="checkbox"
                                          onChange={() =>
                                            setCheckDefaultAddress(
                                              !checkDefaultAddress
                                            )
                                          }
                                          value={checkDefaultAddress}
                                        />
                                        Set as default address
                                      </label>
                                    </div>
                                  </div>
                                  <br />
                                  <br />
                                  <br />
                                  <Button
                                    className="bold"
                                    onClick={() => {
                                      customerAddressUpdate({
                                        variables: {
                                          customerAccessToken:
                                            customerAccessToken.accessToken,
                                          id: addressEdit.id,
                                          address: {
                                            address1: addressInput,
                                            city: cityInput,
                                            company: companyInput,
                                            country: countryInput,
                                            firstName: firstNameInput,
                                            lastName: lastNameInput,
                                            phone: phoneInput,
                                            zip: zipInput,
                                          },
                                        },
                                      })
                                      checkDefaultAddress &&
                                        customerDefaultAddressUpdate({
                                          variables: {
                                            customerAccessToken:
                                              customerAccessToken.accessToken,
                                            addressId: addressEdit.id,
                                          },
                                        }).then(result => alert(result))
                                    }}
                                  >
                                    Update address
                                  </Button>
                                  <Button
                                    className="clear bold"
                                    onClick={() =>
                                      setEditAdressForm(!editAdressForm)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </FormDiv>
                              )
                            }}
                          </Mutation>
                        )
                      }}
                    </Mutation>
                  </div>
                </div>
              )}
              <br />
              <br />
              <AddAddressForm />
            </div>
          )
        }}
      </Query>
    </Layout>
  )
}

export default Addresses
