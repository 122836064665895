import React, { useContext, useEffect, useState } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import {
  Tab as HorizontalTab,
  Tabs as HorizontalTabs,
  TabList as HorizontalTabList,
  TabPanel as HorizontalTabPanel,
} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import StoreContext from '../../context/StoreContext'
import Layout from '../../components/account/Layout'
import Logout from './logout'
import OrderTable from '../../components/OrderTable'
import Navigation from '../../components/Navigation'
import styled from 'styled-components'
import {
  Tabs as VerticalTabs,
  Tab as VerticalTab,
  TabPanel as VerticalTabPanel,
  TabList as VerticalTabList,
} from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'
import {
  BodyText,
  Button,
  Divider,
  FormInput,
  FormLabel,
  HeadingText,
  SubHeadingText,
} from '../../styles'
import media from '../../styles/media'
import firebase from '../../utils/firebase'
import { Mutation } from 'react-apollo'
import DefaultAddressShow from '../../components/account/defaultAddressShow'
import Addresses from './addresses'

const AccountDiv = styled.div`
  margin: var(--gutter-s) 0;
  min-height: 80vh;
  .tablist {
    width: 10vw;
    margin-right: var(--gutter-s);
    border-right: none;
  }
  .rwt__tablist {
    width: 22vw;
    text-align: left;
    border-right: none;
    a {
      color: black;
      text-decoration: none;
      p {
        text-decoration: none !important;
      }
    }
    button {
      padding: 1rem 1rem 1rem 0;
      text-align: left;
      font-size: 12px;
      line-height: 18px;
      border-bottom: 1px solid var(--light-grey);
      a {
        color: black;
        text-decoration: none;
        p {
          text-decoration: none !important;
        }
      }
      &:active {
        color: var(--blue);
        border-right: none !important;
      }
      &:focus {
        border-right: none;
        background-color: transparent;
      }
      button {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0;
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
  .react-tabs {
    min-height: 30vh;
    ul {
      text-align: center;
      padding: 0 var(--gutter-m);
      border-bottom: 1px solid var(--light-grey);
      margin: 0 0 var(--gutter-m);
      justify-content: space-around;
      display: flex;
      li {
        width: 25%;
        margin: 0 var(--gutter-s);
        a {
          color: black;
          text-decoration: none;
          p {
            text-decoration: none !important;
          }
        }
      }
    }
    .react-tabs__tab--selected {
      background: transparent;
      border: none;
      color: var(--blue);
      font-family: 'neue-haas-grotesk-display-bold';
      border-bottom: 3px solid var(--blue);
    }
    .react-tabs__tab-panel {
      margin: 0 var(--gutter-s);
    }
  }
  ${media.laptop`
    margin: var(--gutter-m);
    min-height: 80vh;
    .tablist {
      width: 20vw;
      margin-right: var(--gutter-m);
    button {
      padding: 2rem 1rem;
    }
    }
  .rwt__tablist {
    width: 15vw;
    button {
      text-align: left;
      font-size: 16px;
      line-height: 27px;
      button {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 0;
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
  `}
`

const TabCon = styled.div`
  ${media.laptop`
    margin-left: var(--gutter-m);
  `}
`

const CUSTOMER_INFO = gql`
  query($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      email
      firstName
      phone
      defaultAddress {
        firstName
        lastName
        address1
        city
        zip
        country
      }
      orders(first: 10) {
        edges {
          node {
            name
            totalPrice
            processedAt
            statusUrl
            currencyCode
            lineItems(first: 10) {
              edges {
                node {
                  title
                  quantity
                }
              }
            }
            shippingAddress {
              address1
              city
              lastName
              firstName
              zip
              country
            }
            subtotalPrice
            totalPrice
          }
        }
      }
      addresses(first: 10) {
        edges {
          node {
            address1
            city
            lastName
            firstName
            country
            name
            zip
          }
        }
      }
    }
  }
`

const CUSTOMER_PASSWORD_RESET = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const Index = () => {
  const { customerAccessToken } = useContext(StoreContext)
  const setPasswordForgot = useState(false)
  const [emailReset, setEmailReset] = useState('')
  const setMessageInfo = useState('')
  const [quoteItems, setQuoteItems] = useState([])
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth)
    }
  }, [width])

  useEffect(() => {
    firebase
      .firestore()
      .collection('quotes')
      .onSnapshot(snapshot => {
        const quoteList = snapshot.docs.map(quo => ({
          id: quo.id,
          ...quo.data(),
        }))
        setQuoteItems(quoteList)
      })
  }, [])

  return (
    <Layout>
      <Navigation />
      {width > 800 && (
        <Query
          query={CUSTOMER_INFO}
          variables={{
            customerAccessToken: customerAccessToken.accessToken,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Fetching</div>
            if (error) return <div>Error</div>
            const {
              defaultAddress,
              addresses,
              firstName,
              lastName,
              contact,
            } = data.customer
            return (
              <AccountDiv>
                <VerticalTabs defaultTab="vertical-tab-one" vertical>
                  <VerticalTabList>
                    <VerticalTab tabFor="vertical-tab-one">
                      My Orders
                    </VerticalTab>
                    <VerticalTab tabFor="vertical-tab-two">My Info</VerticalTab>
                    <VerticalTab tabFor="vertical-tab-three">
                      Change Password
                    </VerticalTab>
                    <VerticalTab tabFor="vertical-tab-four">
                      <Logout />
                    </VerticalTab>
                  </VerticalTabList>
                  <VerticalTabPanel tabId="vertical-tab-one">
                    <TabCon>
                      <HeadingText className="black bottomSpace">
                        My Orders
                      </HeadingText>
                      {/* <OrdersList orders={orders} /> */}
                      {customerAccessToken && (
                        <Query
                          query={CUSTOMER_INFO}
                          variables={{
                            customerAccessToken:
                              customerAccessToken.accessToken,
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Fetching orders</div>
                            if (error) return <div>Error</div>
                            const { email } = data.customer
                            return (
                              <OrderTable
                                quoteItems={quoteItems}
                                emailAddress={email}
                                pageShown="orders"
                              />
                            )
                          }}
                        </Query>
                      )}
                      <br />
                      <br />
                      <br />
                      <SubHeadingText className="black">
                        Billing Address
                      </SubHeadingText>
                      <Divider className="bottomSpace" />
                      <DefaultAddressShow
                        defaultAddress={defaultAddress}
                        addressesSize={addresses.edges.length}
                      />
                      <br />
                      <br />
                      <br />
                      <SubHeadingText className="black">
                        Shipping Address
                      </SubHeadingText>
                      <Divider className="bottomSpace" />
                      <DefaultAddressShow
                        defaultAddress={defaultAddress}
                        addressesSize={addresses.edges.length}
                      />
                    </TabCon>
                  </VerticalTabPanel>
                  <VerticalTabPanel tabId="vertical-tab-two">
                    <TabCon>
                      <HeadingText className="black bottomSpace">
                        My Info
                      </HeadingText>
                      <HeadingText className="black smaller">
                        Basic Information
                      </HeadingText>
                      <Divider className="smallSpace" />
                      <SubHeadingText className="black smaller">
                        Name
                      </SubHeadingText>
                      <BodyText className="black client">
                        {firstName} {lastName}
                      </BodyText>
                      <Divider className="smallSpace" />
                      <SubHeadingText className="black smaller">
                        Contact
                      </SubHeadingText>
                      <BodyText className="black client">{contact}</BodyText>
                      <HeadingText className="black smaller topSpace">
                        Address
                      </HeadingText>
                      <Divider className="smallSpace" />
                      <Addresses />
                    </TabCon>
                  </VerticalTabPanel>
                  <VerticalTabPanel tabId="vertical-tab-three">
                    <TabCon>
                      <Mutation mutation={CUSTOMER_PASSWORD_RESET}>
                        {customerRecover => {
                          return (
                            <>
                              <HeadingText className="black bottomSpace">
                                Change Password
                              </HeadingText>
                              <div className="field">
                                <FormLabel
                                  className="label"
                                  htmlFor="loginEmail"
                                >
                                  Email
                                </FormLabel>
                                <div className="control">
                                  <FormInput
                                    className="input"
                                    type="email"
                                    id="loginEmail"
                                    onChange={e =>
                                      setEmailReset(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="control has-text-centered">
                                <Button
                                  className="button"
                                  onClick={() => {
                                    customerRecover({
                                      variables: {
                                        email: emailReset,
                                      },
                                    }).then(() => {
                                      setMessageInfo(
                                        "We've sent you an email with a link to update your password."
                                      )
                                      setPasswordForgot(false)
                                    })
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </>
                          )
                        }}
                      </Mutation>
                    </TabCon>
                  </VerticalTabPanel>
                  <VerticalTabPanel tabId="vertical-tab-four">
                    <TabCon>
                      <HeadingText className="black bottomSpace">
                        Logout
                      </HeadingText>
                    </TabCon>
                  </VerticalTabPanel>
                </VerticalTabs>
              </AccountDiv>
            )
          }}
        </Query>
      )}
      {width < 800 && (
        <Query
          query={CUSTOMER_INFO}
          variables={{
            customerAccessToken: customerAccessToken.accessToken,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Fetching</div>
            if (error) return <div>Error</div>
            const {
              defaultAddress,
              addresses,
              firstName,
              lastName,
              contact,
            } = data.customer
            return (
              <AccountDiv>
                <HorizontalTabs defaultTab="tab-one">
                  <HorizontalTabList>
                    <HorizontalTab tabFor="tab-one">My Orders</HorizontalTab>
                    <HorizontalTab tabFor="tab-two">My Info</HorizontalTab>
                    <HorizontalTab tabFor="tab-three">
                      Change Password
                    </HorizontalTab>
                    <HorizontalTab tabFor="tab-four">
                      <Logout />
                    </HorizontalTab>
                  </HorizontalTabList>
                  <HorizontalTabPanel tabId="tab-one">
                    <TabCon>
                      <HeadingText className="black bottomSpace">
                        My Orders
                      </HeadingText>
                      {/* <OrdersList orders={orders} /> */}
                      {customerAccessToken && (
                        <Query
                          query={CUSTOMER_INFO}
                          variables={{
                            customerAccessToken:
                              customerAccessToken.accessToken,
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Fetching orders</div>
                            if (error) return <div>Error</div>
                            const { email } = data.customer
                            return (
                              <OrderTable
                                quoteItems={quoteItems}
                                emailAddress={email}
                                pageShown="orders"
                              />
                            )
                          }}
                        </Query>
                      )}
                      <br />
                      <br />
                      <br />
                      <SubHeadingText className="black">
                        Billing Address
                      </SubHeadingText>
                      <Divider className="bottomSpace" />
                      <DefaultAddressShow
                        defaultAddress={defaultAddress}
                        addressesSize={addresses.edges.length}
                      />
                      <br />
                      <br />
                      <br />
                      <SubHeadingText className="black">
                        Shipping Address
                      </SubHeadingText>
                      <Divider className="bottomSpace" />
                      <DefaultAddressShow
                        defaultAddress={defaultAddress}
                        addressesSize={addresses.edges.length}
                      />
                    </TabCon>
                  </HorizontalTabPanel>
                  <HorizontalTabPanel tabId="tab-two">
                    <TabCon>
                      <HeadingText className="black bottomSpace">
                        My Info
                      </HeadingText>
                      <HeadingText className="black smaller">
                        Basic Information
                      </HeadingText>
                      <Divider className="smallSpace" />
                      <SubHeadingText className="black smaller">
                        Name
                      </SubHeadingText>
                      <BodyText className="black client">
                        {firstName} {lastName}
                      </BodyText>
                      <Divider className="smallSpace" />
                      <SubHeadingText className="black smaller">
                        Contact
                      </SubHeadingText>
                      <BodyText className="black client">{contact}</BodyText>
                      <HeadingText className="black smaller topSpace">
                        Address
                      </HeadingText>
                      <Divider className="smallSpace" />
                      <Addresses />
                    </TabCon>
                  </HorizontalTabPanel>
                  <HorizontalTabPanel tabId="tab-three">
                    <TabCon>
                      <Mutation mutation={CUSTOMER_PASSWORD_RESET}>
                        {customerRecover => {
                          return (
                            <>
                              <HeadingText className="black bottomSpace">
                                Change Password
                              </HeadingText>
                              <div className="field">
                                <FormLabel
                                  className="label"
                                  htmlFor="loginEmail"
                                >
                                  Email
                                </FormLabel>
                                <div className="control">
                                  <FormInput
                                    className="input"
                                    type="email"
                                    id="loginEmail"
                                    onChange={e =>
                                      setEmailReset(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="control has-text-centered">
                                <Button
                                  className="button"
                                  onClick={() => {
                                    customerRecover({
                                      variables: {
                                        email: emailReset,
                                      },
                                    }).then(() => {
                                      setMessageInfo(
                                        "We've sent you an email with a link to update your password."
                                      )
                                      setPasswordForgot(false)
                                    })
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </>
                          )
                        }}
                      </Mutation>
                    </TabCon>
                  </HorizontalTabPanel>
                  <HorizontalTabPanel tabId="tab-four">
                    <TabCon>
                      <HeadingText className="black bottomSpace">
                        Logout
                      </HeadingText>
                    </TabCon>
                  </HorizontalTabPanel>
                </HorizontalTabs>
              </AccountDiv>
            )
          }}
        </Query>
      )}
    </Layout>
  )
}

export default Index
