import React, { useState, useContext, useEffect } from 'react';
import StoreContext from '../../../context/StoreContext'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import axios from 'axios'
import { Button } from '../../../styles'
import styled from 'styled-components'
import media from '../../../styles/media'

const CUSTOMER_CREATE_ADDRESS = gql`
mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
  customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
    customerAddress {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`

const FormDiv = styled.form`
    input[type=text] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        width: 85%;
        height: 40px;
        margin-bottom: 20px;
        padding: 0 15px;
        border-radius: 0;
        font-family: 'neue-haas-grotesk-display';
        :focus {
            outline: none !important;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        height: 40px;
        margin-bottom: 20px;
        padding: 0 15px;
        color: rgb(118, 118, 118);
        font-family: 'neue-haas-grotesk-display';
        width: 95%;
        :focus {
            outline: none !important;
        }
    }
    label{
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
        font-family: 'neue-haas-grotesk-display-bold';
    }
    ${media.laptop`
        display: grid;
        grid-template-columns: 1fr 1fr;
    `}
`


const AddAddressForm = () => {
    const [addAdressForm, setAddAdressForm] = useState(false);
    const [firstNameInput, setFirstNameInput] = useState("");
    const [lastNameInput, setLastNameInput] = useState("");
    const [companyInput, setCompanyInput] = useState("");
    const [addressInput, setAddressInput] = useState("");
    const [apartmentInput, setApartmentInput] = useState("");
    const [cityInput, setCityInput] = useState("");
    const [countryInput, setCountryInput] = useState("");
    const [zipInput, setZipInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");

    const { customerAccessToken } = useContext(StoreContext);

    const [countriesAll, setCountriesAll] = useState([]);

    const getLocations = () => {    
        return axios('https://restcountries.eu/rest/v2/all')
    }

    useEffect(() => {
        getLocations().then(({ data }) => {setCountriesAll(data)})
      }, []);

    return (
        <>
            <Button className="clear bold" onClick={() => setAddAdressForm(!addAdressForm)}>Add a new adress</Button>
            <br/><br/>
            {
            addAdressForm && (
            <div className="columns is-centered">
                <div className="column is-6 has-text-left">
                    <Mutation mutation={CUSTOMER_CREATE_ADDRESS}>
                        {(customerAddressCreate) => {
                            return (
                                <FormDiv>
                                    <div className="field">
                                        <label className="label" htmlFor="firstNameInput">First Name</label>
                                        <div className="control">
                                            <input className="input" value={firstNameInput} type="text" onChange={(e) => setFirstNameInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label" htmlFor="lastNameInput">Last Name</label>
                                        <div className="control">
                                            <input className="input" value={lastNameInput} type="text" onChange={(e) => setLastNameInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label"  htmlFor="companyInput">Company</label>
                                        <div className="control">
                                            <input className="input" value={companyInput} type="text" onChange={(e) => setCompanyInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label"  htmlFor="addressInput">Address</label>
                                        <div className="control">
                                            <input className="input" value={addressInput} type="text" onChange={(e) => setAddressInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label"  htmlFor="apartmentInput">Apartment, suite, etc.</label>
                                        <div className="control">
                                            <input className="input" value={apartmentInput} type="text" onChange={(e) => setApartmentInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label"  htmlFor="cityInput">City</label>
                                        <div className="control">
                                            <input className="input" value={cityInput} type="text" onChange={(e) => setCityInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field" >
                                        <label className="label"  htmlFor="countryInput">Country</label>
                                        <div className="control">
                                            <div className="select">
                                                <select value={countryInput} onChange={(e) => setCountryInput(e.target.value)} onBlur={(e) => setCountryInput(e.target.value)}>
                                                    {
                                                        countriesAll.map((country)=>(
                                                            <option value={country.name}>{country.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field" >
                                        <label className="label"  htmlFor="countryInput">Province</label>
                                        <div className="control">
                                            <div className="select">
                                                <select defaultValue="Gauteng">
                                                    <option value="Western Cape">Western Cape</option>
                                                    <option value="Eastern Cape">Eastern Cape</option>
                                                    <option value="Northern Cape">
                                                        Northern Cape
                                                    </option>
                                                    <option value="North West">North West</option>
                                                    <option value="Free State">Free State</option>
                                                    <option value="Kwazulu Natal">
                                                        Kwazulu Natal
                                                    </option>
                                                    <option value="Gauteng">Gauteng</option>
                                                    <option value="Limpopo">Limpopo</option>
                                                    <option value="Mpumalanga">Mpumalanga</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label"  htmlFor="zipInput">Postal/Zip Code</label>
                                        <div className="control">
                                            <input className="input" value={zipInput} type="text" onChange={(e) => setZipInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label"  htmlFor="phoneInput">Phone</label>
                                        <div className="control">
                                            <input className="input" value={phoneInput} type="text" onChange={(e) => setPhoneInput(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control">
                                            <label className="checkbox"  htmlFor="checkboxDefaultAddress">
                                                <input type="checkbox" />
                                                {'  '}Set as default address
                                            </label>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <Button
                                        className="bold"
                                        onClick={() => {
                                            customerAddressCreate({
                                                variables: {
                                                    "customerAccessToken": customerAccessToken.accessToken,
                                                    "address": {
                                                        address1: addressInput,
                                                        city: cityInput,
                                                        company: companyInput,
                                                        country: countryInput,
                                                        firstName: firstNameInput,
                                                        lastName: lastNameInput,
                                                        phone: phoneInput,
                                                        zip: zipInput,
                                                    }
                                                }
                                            }).then((result) => {
                                                setAddAdressForm(!addAdressForm)
                                            })
                                        }}
                                    >
                                        Add adress</Button>
                                    <Button className="clear bold" onClick={() => setAddAdressForm(!addAdressForm)} onKeyPress={() => setAddAdressForm(!addAdressForm)}>Cancel</Button>
                                </FormDiv>
                            )
                        }}
                    </Mutation>
                </div>
            </div>
            )}
        </>
    );
};

export default AddAddressForm;